import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperGoodRelationship'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const PandemicInformation = makeShortcode("PandemicInformation");
const Typography = makeShortcode("Typography");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "good-relationship"
    }}>{`Good relationship?`}</h1>
    <TextStepper id="textStepperGoodRelationship" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: "“She's not interested in sharing her thoughts and feelings with me. It's like I'm talking to a brick wall. I feel like we're not close anymore.”",
      long: true
    }, {
      text: '“I can barely get two words out of him.”'
    }, {
      text: '“I need to learn mumbling as a second language.”'
    }]} mdxType="TextStepper" />
    <h2 {...{
      "id": "sound-familiar-youre-not-alone"
    }}>{`Sound familiar? You're not alone`}</h2>
    <p>{`Connecting with your teenager is not always easy. But there are things you can do to build and maintain a good relationship with your teenager. A relationship between a teenager and a parent is a two-way street. You cannot control the behaviour of your teenager. However, you can control your own behaviour, and by doing so, influence your teenager's behaviour.`}</p>
    <p><em parentName="p">{`Connect`}</em>{` is about empowering you to change your behaviour so you can positively influence your teenager. We'll talk about ways to build your relationship and communicate with your teenager.`}</p>
    <PandemicInformation mdxType="PandemicInformation">
  <Typography paragraph mdxType="Typography">
    We’ll also look at some common emotional reactions to the pandemic, and how you can support your teen to cope with
    strong emotions during this time.
  </Typography>
  <Typography paragraph mdxType="Typography">Look out for this icon to find pandemic-specific tips and strategies.</Typography>
    </PandemicInformation>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      